import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useAuth } from '../use-auth';
import useSentance from '../use-sentance';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function ButtonAppBar(props) {
    const auth = useAuth()
    const sentances = useSentance();

    console.log("auth: " + auth.user)
    const classes = useStyles();
    let title = "Login Page"
    if (auth.user) {
        title = "Welcome: " + auth.user.openId.given_name
    }
    console.log(title)
    let actions
    if (auth.user) {
        actions = (
            <>
                <Button color="inherit" onClick={sentances.onAdd}>Add</Button>
                <Button color="inherit" onClick={sentances.loadData}>Load</Button>
                <Button color="inherit" onClick={sentances.updateDate}>Save</Button>
                <Button color="inherit" onClick={auth.signout}>Log Out</Button>
            </>
        )
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    {actions}
                </Toolbar>
            </AppBar>
        </div>
    );
}
import { useLocation } from 'react-router-dom';
import { useAuth } from '../use-auth'
import { Redirect } from "react-router-dom"


import jwt_decode from "jwt-decode";

export default function LoginWithToken({ children }) {
    const location = useLocation()
    const auth = useAuth()

    if (location.hash) {
        auth.signin({ openId: jwt_decode(auth.extractIdToken(location.hash)), token: auth.extractAccessToken(location.hash) }, () => {
        })
        return (
            <Redirect to={location.pathname}></Redirect>
        )
    } else {
        console.log("no token extracted")
    }
    return children
}

import { useAuth } from '../use-auth'

export default function Login() {
    const auth = useAuth()
    const redirecUrl = "https://translationgame.softfronts.com"
    const url =
        `https://login.softfronts.com/login?client_id=1re7f4elc3b828vcsr6ra624kr&response_type=token&scope=openid&redirect_uri=${redirecUrl}`;
    if (!auth.user) {
        window.location.replace(url)
    }
    return (
        <div>
            <h3>Redirect to AWS for Login...</h3>
        </div>
    )
}
import useSentance from "../use-sentance"
import TranslationItem from './TranslationItem'

export default function SentanceList() {
    const sentances = useSentance()
    return (
        (sentances.data) ?
            Object.keys(sentances.data).map((key) => (
                <TranslationItem key={key + sentances.data[key].translation + sentances.data[key].original + sentances.data[key].updated} index={key} data={sentances.data[key]} />
            ))
            : (
                <div><h3> No fragments loaded</h3></div>
            )
    )
}
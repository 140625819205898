import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { green, blueGrey } from '@material-ui/core/colors';

import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: '1rem',
        lineHeight: '1em',
        '& > *': {
            alignSelf: 'center',
        },
    },
    sentance: {
        flex: '90%',
        '& > *': {
            width: '100%',
        },
    },
    edit: {
        backgroundColor: green[50],
    },
    fragment: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        '& > *': {
            paddingLeft: theme.spacing(5),
        }
    },
    notFragment: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },
    fragmentBackgroundColor: {
        backgroundColor: blueGrey[50]
    },
    TextField: {
        padding: 0,
    }
}));

export default function Sentance(props) {
    const classes = useStyles()
    const [editMode, setEditMode] = useState(false)
    const [sentance, setSentance] = React.useState(props.sentance);

    const handleChange = (event) => {
        props.onChange(event.target.value)
        setSentance(event.target.value);
    };

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            setEditMode(false)
        }
    }

    const onBlur = (event) => {
        setEditMode(false)
    }

    const onDoubleClick = (event) => {
        if (editMode)
            return
        else setEditMode(true)
    }

    var out = (<div>{sentance}</div>)
    if (editMode) {
        out = (<TextField multiline value={sentance} onChange={handleChange} />
        )
    }

    let sentanceClass = classes.sentance

    if (props.isFragment) {
        sentanceClass += ' ' + classes.fragment
    } else {
        sentanceClass += ' ' + classes.notFragment
    }

    if (editMode) {
        sentanceClass += ' ' + classes.edit
    } else if (props.isFragment) {
        sentanceClass += ' ' + classes.fragmentBackgroundColor
    }

    return (<div className={classes.root}>
        <Paper className={sentanceClass} elevation={2} square onDoubleClick={onDoubleClick} onKeyUp={onKeyPress} onBlur={onBlur}>{out}</Paper>
        <div>
            <Button onClick={() => setEditMode(!editMode)} >
                <EditIcon />
            </Button>
        </div>
    </div >
    );
}
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import AppBar from './components/AppBar';
import Login from './components/Login';
import AWSLogin from './components/AWSLogin';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import { ProvideAuth } from "./use-auth.js"
import { ProvideSentance } from "./use-sentance.js"
import PrivateRoute from './components/PrivateRoute'
import SentanceList from './components/SentanceList'
import LoginWithToken from './components/LoginWithToken'

const theme = createMuiTheme();

function App() {
    return (
        <ProvideAuth>
            <Router>
                <MuiThemeProvider theme={theme}>
                    <Container fixed>
                        <ProvideSentance>
                            <CssBaseline>
                                <AppBar />
                                <Switch>
                                    <Route path="/Login">
                                        <Login />
                                    </Route>
                                    <Route path="/AWSLogin">
                                        <AWSLogin />
                                    </Route>
                                    <LoginWithToken >
                                        <PrivateRoute path="/">
                                            <SentanceList />
                                        </PrivateRoute>
                                    </LoginWithToken>
                                </Switch>
                            </CssBaseline>
                        </ProvideSentance>
                    </Container>
                </MuiThemeProvider>
            </Router>
        </ProvideAuth>
    )
}
export default App;
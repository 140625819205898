import React, { useContext, useState } from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { useAuth } from './use-auth';

const SentanceContext = React.createContext();
const endpointPrefix = "https://api-translationgame.softfronts.com/api"

export function ProvideSentance({ children }) {
    const sentances = useSentances()
    return <SentanceContext.Provider value={sentances}>{children}</SentanceContext.Provider>
}

export default function useSentance() {

    return useContext(SentanceContext)
}

function useSentances() {

    let auth = useAuth()

    const [data, setData] = useState(JSON.parse(localStorage.getItem('sentances')));
    const handleOriginalChange = (index, original) => {
        console.log("request sync")
        data[index].original = original
        data[index].updated = true
    };

    const handleTranslationChange = (index, translation) => {
        console.log("request sync")
        data[index].translation = translation
        data[index].updated = true
    };

    const updateDate = () => {
        let req = {}
        let selectedKeys = Object.keys(data).filter(key => {
            return data[key].updated
        })

        console.log(selectedKeys)
        if (selectedKeys.length > 0) {
            selectedKeys.forEach(key => {
                req[key] = data[key]
            })
            axios.post(`${endpointPrefix}/update`, req, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': auth.user.token,
                }
            }).then(res => {
                console.log("request completed")
                console.log(res.data)
                Object.keys(res.data).forEach(key => {
                    console.log("marked as synced, index: " + key)
                    data[key].updated = false
                })
                console.log("update data")
                setData(Object.assign({}, data))
            }).catch(err => {
                console.log("error", err)
            })
        } else {
            console.log("no items to sync")
        }
    };

    const loadData = () => {
        console.log("trigger scan")
        axios.get(`${endpointPrefix}/scan`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth.user.token,
            }
        }).then(res => {
            setData(res.data);
        }).catch(err => {
            console.log("error", err)
        })
    };

    const onAdd = () => {
        const uid = uuidv4();
        console.log("add element: " + uid)
        const temp = Object.assign({}, data, { [uid]: {} })
        setData(temp)
    }

    const onRemove = (index) => {
        console.log("remove element: " + index)
        const temp = Object.assign({}, data)
        delete temp[index]
        console.log(temp)
        setData(temp)
    }
    return { data, setData, loadData, updateDate, handleOriginalChange, handleTranslationChange, onAdd, onRemove }
}
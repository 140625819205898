import { useState } from 'react';
import { Button, TextField } from "@material-ui/core"
import { useAuth } from '../use-auth'
import { useHistory, useLocation } from 'react-router-dom';


export default function Login() {
    const auth = useAuth()
    const [loginForm, setLoginForm] = useState({ name: "", token: "" })

    const onChange = (event) => {
        setLoginForm(Object.assign(loginForm, { [event.target.id]: event.target.value }))
    }

    let history = useHistory();
    let location = useLocation();
    const from = location.state || { from: { pathname: "/" } };

    const setLogin = (event) => {
        auth.signin(loginForm, () => {
            history.push(from.from.pathname)
        })
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "300px", "& > *": { padding: "10px" } }}>
            <h2>Login with Facebook</h2>
            <TextField id="name" placeholder="Name" onChange={onChange}></TextField>
            <TextField id="token" placeholder="Token" onChange={onChange}></TextField>
            <Button onClick={setLogin}>Login</Button>
        </div>
    )
}
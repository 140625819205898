import React, { useState, useContext, createContext } from "react";

const authContext = createContext()

export function ProvideAuth({ children }) {
    const auth = useProvideAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext)
}

const userStoreKey = 'Authentication'

function useProvideAuth() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem(userStoreKey)))
    const signin = (paramUser, loginSucessful) => {
        localStorage.setItem(userStoreKey, JSON.stringify(paramUser))
        setUser(paramUser)
        loginSucessful()
    };

    const extractAccessToken = (hash) => {
        let part = hash.substring(hash.indexOf("access_token=") + 13);
        if (part.includes("&") > 0) {
            return part.substring(0, part.indexOf("&"));
        }
        return part
    }

    const extractIdToken = (hash) => {
        let part = hash.substring(hash.indexOf("id_token=") + 9);
        if (part.includes("&") > 0) {
            return part.substring(0, part.indexOf("&"));
        }
        return part
    }

    const signout = () => {
        localStorage.removeItem(userStoreKey)
        setUser(false)
    };

    return {
        user,
        signin,
        signout,
        extractIdToken,
        extractAccessToken,
    };
}
import React, { useState } from 'react';
import Sentance from './Sentance';
import Divider from '@material-ui/core/Divider';

import SyncIcon from '@material-ui/icons/Sync';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import useSentance from '../use-sentance';

const divStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
}

const mainDivStyle = {
    flex: '80%',
}

const Container = {
    display: 'flex',
    flexWrap: 'wrap',
}

function TranslationItem(props) {
    const sentances = useSentance()
    const [updated, setUpdated] = useState(props.data.updated)

    const isFragment = props.data.fragment === "true"
    const index = props.index;

    const handleOriginalChange = (original) => {
        sentances.handleOriginalChange(index, original)
        setUpdated(true)
    };

    const handleTranslationChange = (translation) => {
        sentances.handleTranslationChange(index, translation)
        setUpdated(true)
    };
    return (
        <div style={Container}>
            <div style={mainDivStyle}>
                <Sentance isFragment={isFragment} sentance={props.data.original} onChange={handleOriginalChange} />
                <Sentance isFragment={isFragment} sentance={props.data.translation} onChange={handleTranslationChange} />
                <Divider />
            </div >
            <div style={divStyle}>
                <Button> {updated ? <SyncIcon /> : <DoneIcon />}</Button >
                <Button onClick={() => sentances.onRemove(index)}><DeleteIcon >Remove</DeleteIcon></Button>
            </div>
        </div>
    )
}

export default TranslationItem;